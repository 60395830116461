import React, {useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import call from '../img/call.png'
import email from '../img/email.png'
import location from '../img/location.png'
import Layout from '../components/Layout'
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import EmployeeSlider from "../components/EmployeeSlider";
import downArrow from '../img/awesome-angle-double-down.svg';
import CaseStudyShowcase from "../components/CaseStudyShowcase";
import ReactGA from 'react-ga';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const IndexPageTemplate = ({
                                    hero,
                                    whatwehavelearnt,
                                    caption1,
                                    caption2,
                                    whatwedo,
                                    aboutus,
                                    contactus,
                                    casestudies
                                  }) => {
  function logger(buttonname) {
    // Detect each click of the financial PDF
    ReactGA.event({
      category: 'ButtonClick',
      action: buttonname
    })
  }

  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    })
  }, []);
  return (
    <div>
      <section className="intro container">
        <div
          className={'intro-image'}
          style={{
            // backgroundImage: `url(${hero})`,
          }}
        >
          <PreviewCompatibleImage imageInfo={{image: hero, alt: 'Bulb with gears'}}/>
        </div>
        <div className="caption">
          <div className="caption-1">{caption1}</div>
          <div className="caption-2">{caption2}</div>
          <p className="intro-content">
            At <span className="little">Little</span><span className="r-font">r</span>, we love solving problems. Those
            related to marketing and
            business strategy that would lead to
            improvement in revenue, market share and pricing power.
          </p>
        </div>
        <img src={downArrow} alt={'down arrow'} className="down-arrow"/>

      </section>
      <section className="what-we-do container" data-aos={'fade-up'}>
        <h2 className="title">
          What we <strong>do</strong>
        </h2>
        <div className="section-body">
          <div className="image" >
            <PreviewCompatibleImage imageInfo={{image: whatwedo.image, alt: 'what we do illustration'}}/>
          </div>
          <div className="textarea" >
            <h3 className="section-intro">
              {whatwedo.sectionintro}
            </h3>
            <p className="content">
              {whatwedo.content}
            </p>
            <Link to={'/what-we-do'} className={'btn'} onClick={() => logger('what we do')}>Explore</Link>
          </div>
        </div>
      </section>
      <section className="what-we-have-done container" data-aos={'fade-up'}>
        {casestudies && <CaseStudyShowcase casestudies={casestudies}/>}
      </section>
      <section className="what-we-have-learnt container" data-aos={'fade-up'}>
        <h2 className="title">
          What we <strong>have learnt</strong>
        </h2>
        <div className="section-body">
          <div className="image">
            <PreviewCompatibleImage imageInfo={{image: whatwehavelearnt.image, alt: 'What we have learnt'}}/>
          </div>
          <div className="textarea">
            <p className="content">
              {whatwehavelearnt.content}
            </p>
            <ul className="learning-list">
              <li>Choice of market opportunities</li>
              <li>Benchmark pricing</li>
              <li>Forecasting market shares</li>
            </ul>
            <Link to={'/what-we-have-learnt'} className={'btn'} onClick={() => logger('what we learnt')}>Explore</Link>
          </div>
        </div>
      </section>
      <section className="about-us" data-aos={'fade-up'}>
        <div className={'container'}>
          <h2 className="title">About Us</h2>
        </div>
        <EmployeeSlider employees={aboutus.people}/>
      </section>
      <section className="contact-us container" data-aos={'fade-up'}>
        <h2 className="title">Contact Us</h2>
        <div className="section-body">
          <ul className="contacts-desktop">
            <li><a
              target="_blank" rel="noopener noreferrer"
              onClick={() => logger('location from home page')}
              href="https://www.google.co.in/maps/dir//Littler+associates,+No.+5,+II+Floor,+Sriram's+Mansarovar,+H,+18,+South+Ave,+Kamaraj+Nagar,+Thiruvanmiyur,+Chennai,+Tamil+Nadu+600041/@13.0127808,80.2132737,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a525d5c2f0f2c6d:0xebd68571f71591a3!2m2!1d80.257798!2d12.984339">
              <img src={location} alt="location" className="location-d"/>
              <span> Littler associates, K202, "The Attrium", 22, Kalakshetra Road, Thiruvanmiyur, Chennai 600041</span>
            </a>
            </li>
            <li>
              <a href="tel:+919840021792" target="_blank" rel="noopener noreferrer"
                 onClick={() => logger('phone from home')}>
                <img src={call} alt="call" className="call-d"/>
                <span>+91 98400 21792</span>
              </a>
            </li>
            <li>
              <a href="mailto:admin@littlerassociates.com" target="_blank" rel="noopener noreferrer"
                 onClick={() => logger('email from home')}>
                <img src={email} alt="email" className="email-d"/>
                <span>admin@littlerassociates.com</span>
              </a>
            </li>
          </ul>
          <div className="image-desktop">
            <PreviewCompatibleImage imageInfo={{image: contactus.desktopimage, alt: 'contact us'}}/>
          </div>
          <div className="image-mobile">
            <PreviewCompatibleImage imageInfo={{image: contactus.mobileimage, alt: 'contact us'}}/>
          </div>
          <div className="contacts">
            <a
              target="_blank" rel="noopener noreferrer"
              onClick={() => logger('location from home')}
              href="https://www.google.co.in/maps/dir//Littler+associates,+No.+5,+II+Floor,+Sriram's+Mansarovar,+H,+18,+South+Ave,+Kamaraj+Nagar,+Thiruvanmiyur,+Chennai,+Tamil+Nadu+600041/@13.0127808,80.2132737,14z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a525d5c2f0f2c6d:0xebd68571f71591a3!2m2!1d80.257798!2d12.984339">
              <img src={location} alt="location" className="location"/>
            </a>
            <a href="tel:+919840021792" target="_blank" rel="noopener noreferrer"
               onClick={() => logger('phone from home')}>
              <img src={call} alt="call" className="call"/>
            </a>
            <a href="mailto:admin@littlerassociates.com" target="_blank" rel="noopener noreferrer"
               onClick={() => logger('email from home')}>
              <img src={email} alt="email" className="email"/>
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}


const IndexPage = ({data}) => {
  const {frontmatter} = data.markdown;
  return (
    <Layout>
      <IndexPageTemplate
        hero={frontmatter.hero}
        whatwehavelearnt={frontmatter.whatwehavelearnt}
        image={frontmatter.hero.childImageSharp.fluid}
        caption1={frontmatter.caption1}
        caption2={frontmatter.caption2}
        whatwedo={frontmatter.whatwedo}
        aboutus={frontmatter.aboutus}
        contactus={frontmatter.contactus}
        casestudies={data.casestudies}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    contactus: file(relativePath: { eq: "contactus.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    casestudies: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "what-we-have-done" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                icon {
                 publicURL
                }
              }
            }
        }
    }
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "index-page" } }
    ) {
      frontmatter {
        caption1
        caption2
        hero {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        whatwedo {
          sectionintro
          content
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        whatwehavelearnt {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          content
        }
        aboutus{
          people {
            image {
              id
              childImageSharp{
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            position
            description
          }
        }
        contactus {
         desktopimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        mobileimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
