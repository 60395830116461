import React, {useRef} from 'react';
import CaseStudyCard from "./CaseStudyCard";
import leftarrow from "../img/arrow-left-desktop.svg";
import rightarrow from "../img/arrow-right-desktop.svg";
import Carousel, {consts} from 'react-elastic-carousel';
/* eslint-disable */
export default function ({casestudies}) {

  const ref = useRef(null);
  const breakPoints = [
    {width: 1, itemsToShow: 2, itemsToScroll: 2},
    {width: 850, itemsToShow: 3, itemsToScroll: 3},
    {width: 1150, itemsToShow: 4, itemsToScroll: 4},
  ];

  function myArrow({type, onClick, isEdge}) {
    const pointer = type === consts.PREV ? '' : ''
    return (
      <button className={'is-transparent'} onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    )
  }

  return <>
    <div className={'header'}>
      <h2 className="title">
        What we <strong>have done</strong>
      </h2>
      <div>
        <button className={'is-transparent scroll-button'} onClick={() => ref.current.slidePrev()}><img src={leftarrow}
                                                                                                        alt={'left arrow'}/>
        </button>
        <button className={'is-transparent scroll-button'} onClick={() => ref.current.slideNext()}><img src={rightarrow}
                                                                                                        alt={'right arrow'}/>
        </button>
      </div>
    </div>
    <Carousel breakPoints={breakPoints}
              ref={ref}
              enableAutoPlay autoPlaySpeed={6000}
              transitionMs={1000}
              easing={'ease-in'}
              itemPadding={[0, 0, 75, 0]}
              renderArrow={myArrow}
              renderPagination={({pages, activePage, onClick}) => {
                return (
                  <ul className={'dots'}>
                    {pages.map(page => {
                      const isActivePage = activePage === page
                      return (
                        <li
                          key={page}
                          onClick={() => onClick(page)}
                          className={isActivePage ? "dot active" : 'dot'}/>
                      )
                    })}
                  </ul>
                )
              }}
    >
      {[...casestudies.edges].map((edge) => <CaseStudyCard
        key={edge.node.fields.slug}
        title={edge.node.frontmatter.title}
        icon={edge.node.frontmatter.icon.publicURL}
        id={edge.node.id}
        link={'/what-we-have-done'} isActive/>)}
    </Carousel>
  </>;
}
