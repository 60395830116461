import React, {useState} from 'react';
import leftIcon from "../img/Icon-left.png";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import rightIcon from "../img/Icon-right.png";
import leftDesktop from '../img/arrow-left-desktop.svg';
import rightDesktop from '../img/arrow-right-desktop.svg';
import {Link} from "gatsby";

export default function ({employees}) {
  const [index, setIndex] = useState(0);
  const employee = employees[index];

  function next() {
    setIndex((index + 1) % employees.length);
  }

  function previous() {
    const newIndex = index - 1;
    setIndex(newIndex < 0 ? employees.length - 1 : newIndex);
  }

  return <div className="employee-container">
    <div className={'employee container'}>
      <button onClick={previous} className="left-desktop">
        <img src={leftDesktop} alt="left"/>
      </button>
      <div className="image">
        <button onClick={previous} className="is-transparent left">
          <img src={leftIcon} alt="left"/>
        </button>
        <div className="people-img">
          <PreviewCompatibleImage imageInfo={{image: employee.image, alt: employee.name}}/>
        </div>
        <button onClick={next} className="is-transparent right">
          <img src={rightIcon} alt="right"/>
        </button>
      </div>
      <div className="textarea">
        <div className="name">{employee.name}</div>
        {employee.position && <div className="position">{employee.position}</div>}
        <p className="content">
          {employee.description}
        </p>
        <Link to={'/about-us'} className={'btn'}>Know more</Link>
      </div>
      <button onClick={next} className="right-desktop">
        <img src={rightDesktop} alt="right"/>
      </button>
    </div>
  </div>
}
