import React from 'react';
import {Link} from 'gatsby';
import moreinfo from '../img/more-info-arrow.svg';
import {navigate} from 'gatsby';
/* eslint-disable */
export default function ({icon, title, link, id, isActive}) {
  return <div className={isActive ? "active card" : "card"} onClick={() => navigate(link, {state: {id}})}>
    <div className="image"><img src={icon} alt={title}/></div>
    <h6>{title}</h6>
    <Link to={link} state={{id}}>Read Case Study <img src={moreinfo} alt={'More Info'}/></Link>
  </div>

}
